import React from "react"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProgressPage from "../components/ProgressPage"
import { renderHtmlBlocks } from "../helpers/render"
import Breadcrumbs from "../components/Breadcrumbs"
import Heading from "../components/Elements/Heading"
import { getCustomStyles } from "../helpers/styles"

import dots from "../assets/images/decor-dots.png"
import ITPlatform from "../componentsNonAdmin/ItPlatform";

const PlatformPage = ({ data, location, pageContext }) => {
  const { t } = useTranslation()
  const siteTitle = data.site.siteMetadata.title
  const { seo } = data?.wpPage

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={
          seo?.title ||
          t("Data Management Platform - eRetail платформа DV Group")
        }
        description={seo?.metaDescription || ""}
        keywords={seo?.metaKeywords || ""}
      />
      <div className="platform-page">
        <article
          className="platform-page-container"
          itemScope
          itemType="http://schema.org/Article"
        >
          <section itemProp="articleBody">
            <ITPlatform />
          </section>
        </article>
      </div>
    </Layout>
  )
}

export default PlatformPage

export const pageQuery = graphql`
  query ($language: String!) {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "platform" }, status: { eq: "publish" }) {
      title
      seo {
        metaDescription
        metaKeywords
        title
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
